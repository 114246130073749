var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "计划历史",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (plan, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-container",
      on: {
        "click": function click($event) {
          return _vm.toGoodsWarehouseDetailView(plan.goods_id, plan.id);
        }
      }
    }, [_c('van-cell', {
      attrs: {
        "title": "计划日期",
        "value": plan.created_at.substring(0, 10)
      }
    }), plan.is_after ? _c('van-cell', {
      attrs: {
        "title-class": "tips",
        "title": "提示：此商品前天有【退货】，需盘点一次"
      }
    }) : _vm._e(), plan.is_order ? _c('van-cell', {
      attrs: {
        "title-class": "tips",
        "title": "提示：此商品前天有【出货】，需盘点一次"
      }
    }) : _vm._e(), plan.end_at ? _c('van-cell', {
      attrs: {
        "title": "完成时间",
        "value": plan.end_at
      }
    }) : _vm._e(), _c('div', {
      staticClass: "goods"
    }, [_c('div', [_c('van-image', {
      staticClass: "goods-image",
      attrs: {
        "src": plan.goods.product.image
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(plan.goods.product.name))]), _c('div', {
      staticClass: "goods-size"
    }, [_c('span', [_vm._v("规格：1 * " + _vm._s(plan.goods.product.size))])]), _c('div', {
      staticClass: "goods-storage"
    }, [_c('span', [_vm._v("储位：" + _vm._s(plan.goods.default_in_warehouse.storage))])])])])], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }